import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import Sliders from "./Sliders";
import "./CSS/sliderStyles.css";

const CategoryBar = () => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [categories, setCategories] = useState([]);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen((prevState) => !prevState);
    if (isDropdownOpen) {
      setIsDropdownOpen(false); // Close dropdown when closing the mobile menu
    }
  };

  const toggleDropdown = () => {
    setIsDropdownOpen((prevState) => !prevState);
  };

  const closeDropdown = () => {
    setIsDropdownOpen(false);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!event.target.closest(".h-category")) {
        closeDropdown();
      }
    };
    document.addEventListener("mousedown", handleClickOutside);

    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  useEffect(() => {
    // Fetch categories from the API
    const fetchCategories = async () => {
      try {
        const response = await axios.get(
          "http://39.61.51.195:8004/account/category/"
        );
        setCategories(response.data); // Assuming the API response is an array of categories
      } catch (error) {
        console.error("Error fetching categories:", error);
      }
    };

    fetchCategories();
  }, []);

  // search functionality
  const navigate = useNavigate();

  const [searchQuery, setSearchQuery] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("All Category");
  const handleSearch = () => {
    navigate(`/products?search=${encodeURIComponent(searchQuery)}`);
  };

  return (
    <>
      <div className="h-header">
        {/* Left side of the header */}
        <div className="h-header-left">
          {/* Navigation Links */}
          <div className="desktop-menu">
            <div className="h-container">
              <div className="h-category" onClick={toggleDropdown}>
                <span>
                  <strong className="cate-strong">
                    <i className="fa fa-th-list p-2" aria-hidden="true"></i>
                    All Categories
                  </strong>
                </span>
                {isDropdownOpen && (
                  <ul className="h-dropdown">
                    {categories.map((category, index) => (
                      <Link
                        key={category.category_id}
                        to={`/products?category=${category.category_id}`}
                        className="slide-link"
                      >
                        <li key={index}>{category.name}</li>
                      </Link>
                    ))}
                  </ul>
                )}
              </div>
            </div>
            <Link to="/" className="h-heading">
              Home
            </Link>
            <Link to="/shop" className="h-heading">
              Shop
            </Link>
            <Link to="/aboutus" className="h-heading">
              About
            </Link>
            <Link to="/contactus" className="h-heading">
              Contact Us
            </Link>
          </div>

          {/*  serach */}
          <div className="search-bar">
            <input
              type="text"
              placeholder="Search Item..."
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />

            <select
              value={selectedCategory}
              onChange={(e) => setSelectedCategory(e.target.value)}
              className="category-select"
            >
              <option>All Category</option>
              {categories.map((category) => (
                <option key={category.id} value={category.name}>
                  {category.name}
                </option>
              ))}
            </select>

            <button onClick={handleSearch}>
              <i className="fa fa-search text-white"></i>
            </button>
          </div>
          {/* Burger Menu for Mobile */}
          <div className="burger-menu" onClick={toggleMobileMenu}>
            {isMobileMenuOpen ? (
              <i
                className="fa fa-times burger-icon-setting"
                aria-hidden="true"
              ></i> // Close icon
            ) : (
              <i
                className="fa fa-bars burger-icon-setting"
                aria-hidden="true"
              ></i> // Burger icon
            )}
          </div>

          {isMobileMenuOpen && (
            <div className="mobile-menu">
              <div
                className="h-container"
                style={{ marginLeft: "1rem", marginTop: "5px" }}
              >
                <div className="h-category" onClick={toggleDropdown}>
                  <span>
                    <strong className="cate-strong">
                      <i className="fa fa-th-list p-2" aria-hidden="true"></i>
                      All Categories
                    </strong>
                  </span>
                  {isDropdownOpen && (
                    <ul className="h-dropdown">
                      {categories.map((category, index) => (
                        <Link
                          key={category.category_id}
                          to={`/products?category=${category.category_id}`}
                          className="slide-link"
                        >
                          <li>{category.name}</li>
                        </Link>
                      ))}
                    </ul>
                  )}
                </div>
              </div>
              <Link to="/" className="h-heading" onClick={toggleMobileMenu}>
                Home
              </Link>
              <Link to="/shop" className="h-heading" onClick={toggleMobileMenu}>
                Shop
              </Link>
              <Link
                to="/aboutus"
                className="h-heading"
                onClick={toggleMobileMenu}
              >
                About
              </Link>
              <Link
                to="/contactus"
                className="h-heading"
                onClick={toggleMobileMenu}
              >
                Contact Us
              </Link>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default CategoryBar;
