import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

const SellerStore = () => {
  const { sellerId } = useParams();
  const [store, setStore] = useState(null);
  const [error, setError] = useState(false); // To track if data is unavailable

  useEffect(() => {
    fetch(`http://39.61.51.195:8004/account/store/${sellerId}/`)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Seller data not found");
        }
        return response.json();
      })
      .then((data) => {
        setStore(data);
        setError(false); // Reset error in case data loads successfully
      })
      .catch(() => {
        setError(true); // Set error if data fetch fails
      });
  }, [sellerId]);

  if (error) {
    return (
      <div className="seller-store p-4">
        <h1>This is a new seller.</h1>
      </div>
    );
  }

  if (!store) {
    return <div>Loading store details...</div>;
  }

  return (
    <div className="seller-store">
      <h1>{store.store_name}</h1>
      <img src={store.banner} alt={`${store.store_name} Banner`} />
      <p>
        <strong>Phone:</strong> {store.phone_number}
      </p>
      <p>
        <strong>Email:</strong> {store.email}
      </p>
      <p>
        <strong>Opening Hours:</strong> {store.opening_hour}
      </p>
      <p>
        <strong>Closing Hours:</strong> {store.closing_hour}
      </p>
    </div>
  );
};

export default SellerStore;
