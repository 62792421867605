import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useUserContext } from "../../Context/Context";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const DetailPage = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { addToCart, user, cart } = useUserContext(); // Assuming `cart` is part of the context
  
  const [product, setProduct] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const [zoomStyle, setZoomStyle] = useState({});

  useEffect(() => {
    fetch(`http://39.61.51.195:8004/product/${id}/`)
      .then((response) => response.json())
      .then((data) => {
        setProduct(data);
        setSelectedImage(data.images[0].image);
      })
      .catch((error) =>
        console.error("Error fetching product details:", error)
      );
  }, [id]);

  const handleAddToCart = (product) => {
    const alreadyInCart = cart.some((item) => item.id === product.id);

    if (alreadyInCart) {
      toast.info("Product is already in the cart!");
    } else {
      addToCart(product);
      toast.success("Product added to cart successfully!");
    }
  };

  const handleMouseMove = (e) => {
    const { left, top, width, height } = e.target.getBoundingClientRect();
    const x = ((e.clientX - left) / width) * 100;
    const y = ((e.clientY - top) / height) * 100;

    setZoomStyle({
      backgroundImage: `url(${selectedImage})`,
      backgroundPosition: `${x}% ${y}%`,
      backgroundSize: "200%",
    });
  };

  const handleMouseLeave = () => {
    setZoomStyle({});
  };

  if (!product) return <div>Loading...</div>;

  return (
    <div className="product-details-container">
      <ToastContainer />
      <div className="product-details">
        <div className="product-images">
          <div
            className="main-image-container"
            onMouseMove={handleMouseMove}
            onMouseLeave={handleMouseLeave}
            style={zoomStyle}
          >
            <img
              src={selectedImage}
              alt={product.name}
              className="main-image"
              style={zoomStyle.backgroundImage ? { opacity: 0 } : {}}
            />
          </div>
          <div className="thumbnail-container">
            {product.images.map((img) => (
              <img
                key={img.id}
                src={img.image}
                alt={product.name}
                className="thumbnail"
                onClick={() => setSelectedImage(img.image)}
              />
            ))}
          </div>
        </div>
        <div className="product-info">
          <h1 className="product-name">{product.name}</h1>
          <p className="product-description">{product.description}</p>
          <p className="product-stock">Stock: {product.stock}</p>
          <p className="product-price">Rs {product.price}</p>
          <div className="button-container">
            <button
              className="add-to-cart-btn"
              onClick={() => handleAddToCart(product)}
            >
              Add to Cart
            </button>
            <button
              className="buy-now-btn"
              onClick={() => navigate(`/create-order/${product.id}`)}
            >
              Buy Now
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DetailPage;
