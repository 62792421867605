import React from 'react';
import './CSS/Banner.css';  // Import CSS for styling

const Banner = () => {
  // Store image URLs in an array
  const features = [
    {
      icon: "🚀",
      title: "Free Delivery",
      description: "From $99.00",
    },
    {
      icon: "💰",
      title: "Money Guarantee",
      description: "36 days back",
    },
    {
      icon: "💳",
      title: "Payment Method",
      description: "secure payment",
    },
    {
      icon: "🎧",
      title: "Support 24/7",
      description: "Online 24 hours",
    },
  //  {
  //     icon: "☂️",
  //     title: "100% Safe",
  //     description: "secure shopping",
  //   },
  ];


  const bannerImages = [
    "https://demo1.wpthemego.com/themes/sw_bosmarket/wp-content/uploads/2017/12/banner1-1.jpg",
    "https://demo1.wpthemego.com/themes/sw_bosmarket/wp-content/uploads/2017/12/banner2-1.jpg",
    "https://demo1.wpthemego.com/themes/sw_bosmarket/wp-content/uploads/2017/12/banner3-1.jpg"
  ];

  return (
    <>
    <div className='pt-4'></div>
    <div className="banner-container ">
      {bannerImages.map((image, index) => (
        <div key={index} className="banner-item  ">
          <img src={image} alt={`Banner ${index + 1}`} className="banner-img "   />
          
        </div>
      ))}
    </div>
    <div className='p-3'>
    <div className="features-container">
      {features.map((feature, index) => (
        <div className="feature-item" key={index}>
          <div className="feature-icon">{feature.icon}</div>
          <div className="feature-content">
            <h3>{feature.title}</h3>
            <p>{feature.description}</p>
          </div>
        </div>
      ))}
    </div>
    </div>
  </>
  );
};

export default Banner;
