import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import '../CSS/Order.css';
import { useUserContext } from "../../Context/Context";

const CreateOrderPage = () => {
  const { id } = useParams(); // Product ID passed via the URL
  const navigate = useNavigate();
  const { userData } = useUserContext(); // Assuming user data is in context

  // Form fields
  const [name, setName] = useState(userData?.name || "");
  const [PhoneNumber, setPhoneNumber] = useState("");
  const [address, setAddress] = useState("");
  const [orderedItem, setOrderedItem] = useState(1); // Default quantity is 1
  const [totalPrice, setTotalPrice] = useState(0);
  const [product, setProduct] = useState(null); // Holds product details
  const [error, setError] = useState(null);

  // Redirect to login if not authenticated
  useEffect(() => {
    if (!userData || !userData.id) {
      alert("Please log in to place an order.");
      navigate("/login");
    }
  }, [userData, navigate]);

  // Fetch product details
  useEffect(() => {
    fetch(`http://39.61.51.195:8004/product/${id}/`)
      .then((response) => response.json())
      .then((data) => {
        setProduct(data);
        setTotalPrice(data.price); // Initialize total price
      })
      .catch((error) =>
        console.error("Error fetching product details:", error)
      );
  }, [id]);

  // Update total price when quantity changes
  useEffect(() => {
    if (product) {
      setTotalPrice(product.price * orderedItem);
    }
  }, [orderedItem, product]);

  // Handle form submission
  const handleOrderSubmit = () => {
    // Basic validation
    if (!name || !PhoneNumber || !address || !totalPrice) {
      setError("Please fill in all fields.");
      return;
    }

    // Create the order payload
    const orderData = {
      name,
      ordered_item: orderedItem,
      Phone_no: PhoneNumber,
      address,
      total_price: totalPrice,
      refund_status: "not_requested",
      clear_status: "not_clear",
      order_status: "processing",
      seller: 1,
      user: userData.id,
      product: id,
    };

    // Submit the order
    fetch("http://39.61.51.195:8004/account/create-order/", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(orderData),
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error("Failed to create order");
        }
      })
      .then((order) => {
        alert("Order created successfully!");
        navigate("/profile");
      })
      .catch((error) => {
        console.error("Error creating order:", error);
        setError("Failed to create order. Please try again.");
        alert("Failed to create order. Please try again.");
      });
  };

  if (!product) return <div>Loading product details...</div>;

  return (
    <div className="create-order-container">
      <h1>Complete Your Order</h1>
      {error && <p className="error-message">{error}</p>}

      <div className="order-content">
        {/* Product Image */}
        <div className="product-image-section">
          <img src={product.images[0].image} alt={product.name} />
          <h2>{product.name}</h2>
          <p>Price: ${product.price}</p>
        </div>

        {/* Order Form */}
        <div className="order-form-section">
          <form onSubmit={(e) => e.preventDefault()}>
            <div className="form-group">
              <label htmlFor="name">Your Name</label>
              <input
                type="text"
                id="name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                placeholder="John Doe"
              />
            </div>
            <div className="form-group">
              <label htmlFor="cardNumber">Phone Number</label>
              <input
                type="text"
                id="cardNumber"
                value={PhoneNumber}
                onChange={(e) => setPhoneNumber(e.target.value)}
                placeholder="1234-90123456"
              />
            </div>
            <div className="form-group">
              <label htmlFor="orderedItem">Quantity</label>
              <input
                type="number"
                id="orderedItem"
                value={orderedItem}
                onChange={(e) => setOrderedItem(e.target.value)}
                min="1"
              />
            </div>
            <div className="form-group">
              <label htmlFor="address">Shipping Address</label>
              <textarea
                id="address"
                value={address}
                onChange={(e) => setAddress(e.target.value)}
                rows="4"
                placeholder="123 Main St, City, State"
              ></textarea>
            </div>
            <div className="form-group">
              <label htmlFor="totalPrice">Total Price</label>
              <input type="number" id="totalPrice" value={totalPrice} readOnly />
            </div>
            <button className="submit-order-btn" onClick={handleOrderSubmit}>
              Place Order
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default CreateOrderPage;
