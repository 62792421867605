import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useUserContext } from "../Context/Context";
import "./CSS/Profile.css";

const Profile = () => {
  const { userData, setUserData } = useUserContext();
  const navigate = useNavigate();
  const [orders, setOrders] = useState([]);
  const [products, setProducts] = useState([]);
  const [orderStats, setOrderStats] = useState({
    total: 0,
    pending: 0,
    delivered: 0,
    canceled: 0,
    processing: 0,
    totalPrice: 0,
  });

  useEffect(() => {
    const fetchUserData = async () => {
      const token = localStorage.getItem("token");
      if (!token) {
        navigate("/login");
        return;
      }

      try {
        const response = await fetch("http://39.61.51.195:8004/account/user", {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        });

        if (response.ok) {
          const data = await response.json();
          setUserData(data);
        } else {
          console.error("Failed to fetch user data");
          navigate("/login");
        }
      } catch (error) {
        console.error("Error:", error);
        navigate("/login");
      }
    };

    const fetchOrders = async () => {
      const token = localStorage.getItem("token");
      if (!token) {
        navigate("/login");
        return;
      }

      try {
        const response = await fetch(
          "http://39.61.51.195:8004/account/create-order/",
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        if (response.ok) {
          const data = await response.json();
          setOrders(data);
        } else {
          console.error("Failed to fetch orders");
        }
      } catch (error) {
        console.error("Error:", error);
      }
    };

    const fetchProducts = async () => {
      try {
        const response = await fetch("http://39.61.51.195:8004/product-create/");
        if (response.ok) {
          const data = await response.json();
          setProducts(data);
        } else {
          console.error("Failed to fetch products");
        }
      } catch (error) {
        console.error("Error:", error);
      }
    };

    fetchUserData();
    fetchOrders();
    fetchProducts();
  }, [navigate, setUserData]);

  useEffect(() => {
    // Calculate the order statistics once orders are fetched
    const calculateOrderStats = () => {
      const stats = {
        total: 0,
        pending: 0,
        processing: 0,
        delivered: 0,
        canceled: 0,
        totalPrice: 0, // ensure it's set as a number
      };
  
      orders.forEach((order) => {
        if (order.user === userData.id) {
          stats.total += 1;
          if (order.order_status === "pending") {
            stats.pending += 1;
          } else if (order.order_status === "delivered") {
            stats.delivered += 1;
          } else if (order.order_status === "canceled") {
            stats.canceled += 1;
          }
           else if (order.order_status === "processing") {
            stats.processing += 1;
          }
  
          const product = products.find((prod) => prod.id === order.product);
          if (product) {
            // Safely add product price, ensure it's treated as a number
            stats.totalPrice += Number(product.price) || 0;
          }
        }
      });
  
      setOrderStats(stats);
    };
  
    if (orders.length > 0 && products.length > 0 && userData) {
      calculateOrderStats();
    }
  }, [orders, products, userData]);
  
  
  const handleLogout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("isLoggedIn");
    navigate("/");
    setUserData(null);
  };

  if (!userData) {
    return <div>Loading...</div>;
  }

  return (
    <div className="container">
      <div className="profile-header">
        <h1>My Profile</h1>
        <h1>{userData.name || "N/A"}</h1>
        <button onClick={handleLogout}>Logout</button>
      </div>

      <div className="profile-info">
        <h2>Personal Information</h2>
        <div className="profile-details">
          <div>
            <strong>Name:</strong> {userData.name || "N/A"}
          </div>
          <div>
            <strong>Email:</strong> {userData.email || "N/A"}
          </div>
          <div>
            <strong>Phone:</strong> {userData.phone_number || "N/A"}
          </div>
          <div>
            <strong>Address:</strong> {userData.address || "N/A"}
          </div>
        </div>
      </div>

      <div className="orders-section">
        <h2>My Orders</h2>
        <div className="order-stats">
          <div><strong>Total Orders:</strong> {orderStats.total}</div>
          <div><strong>Pending Orders:</strong> {orderStats.pending}</div>
          <div><strong>Delivered Orders:</strong> {orderStats.delivered}</div>
          <div><strong>Canceled Orders:</strong> {orderStats.canceled}</div>
          <div><strong>processing Orders:</strong> {orderStats.processing}</div>
          <div><strong>Total Order Amount: </strong>PKR{orderStats.totalPrice.toFixed(2)}</div>
        </div>
        {orders.length === 0 ? (
          <p>No orders found.</p>
        ) : (
          <ul>
            {orders
              .filter((order) => order.user === userData.id)
              .map((order) => {
                const product = products.find(
                  (prod) => prod.id === order.product
                );
                return (
                  <li key={order.id} className="order-item">
                    <div className="order-product">
                      <img
                        src={product?.images[0]?.image || ""}
                        alt={product?.name || "Product"}
                        className="profile-product-image"
                      />
                    </div>
                    <div className="order-details">
                      <div className="detail-row">
                        <strong>Order ID:</strong> {order.id} <strong> Qty:</strong> {order.ordered_item}
                      </div>
                      <div className="detail-row">
                        <strong>User:</strong> {userData.name || "N/A"}
                      </div>
                      <div className="detail-row">
                        <strong>Price:</strong> {product?.price || "N/A"}
                      </div>
                      <div className="detail-row">
                        <strong>Address:</strong> {order.address}
                      </div>
                      <div className="detail-row">
                        <strong>Product:</strong> {product?.name || "N/A"}
                      </div>
                      <div className="detail-row">
                        <strong>Status:</strong> {order.order_status}
                      </div>
                    </div>
                  </li>
                );
              })}
          </ul>
        )}
      </div>
    </div>
  );
};

export default Profile;
