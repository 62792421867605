import React from 'react'

function SendMessageBtn() {
    return (
        <>
            <button className='YsendMessageBtn'>Send Message</button>
        </>
    )
}

export default SendMessageBtn