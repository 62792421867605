import React, { useState, useEffect, useRef } from "react";
import "./Footer.css";
import { Link } from "react-router-dom";

const Footer = () => {
  // tags from api
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    fetch("http://39.61.51.195:8004/account/category/")
      .then((response) => response.json())
      .then((data) => setCategories(data))
      .catch((error) => console.error("Error fetching categories:", error));
  }, []);

  return (
    <footer className="footer">
      <div className="footer-top">
        <div className="follow-us">
          <div className="social-icons">
            <a href="#" className="icons fa-brands fa-facebook-f"></a>
            <a href="#" className="icons fa-brands fa-twitter "></a>
            <a href="#" className="icons fa-brands fa-pinterest"></a>
            <a href="#" className="icons fa-brands fa-instagram"></a>
            <a href="#" className="icons fa-brands fa-google"></a>
          </div>
        </div>

        <div className="newsletter">
          <input type="email" placeholder="Enter your mail..." />
          <button>SUBSCRIBE</button>
        </div>
      </div>
      <div className="footer-main">
        <div className="column">
          <p className="p-heading">About Us</p>
          <ul>
            <li>48-A Main Gulberg, Lahore Pakistan</li>
            <li>dehleze@gmail.Com</li>
            <li>(+92) 300-8410868</li>
            <li>Mon - Fri (09:00 AM to 04:50 PM)</li>
          </ul>
        </div>
        <div className="column">
          <p className="p-heading">Our Services</p>
          <ul>
            <Link className="footer-button" to={"/terms-&-condition"}>
              Terms & Condition
            </Link>
            <li>New Collection</li>
            <li>Contact Us</li>
            <Link className="footer-button" to={"/policies-&-guidelines"}>
              Policies-&-Guidelines
            </Link>
          </ul>
        </div>
        <div className="column">
          <p className="p-heading">Information</p>
          <ul>
            <li>New Collection</li>
            <li>Contact Us</li>
            <li>Latest News</li>
            <li>Our Sitemap</li>
          </ul>
        </div>
        <div className="column">
          <p className="p-heading">Popular Tags</p>
          <div className="tags">
            {categories.map((category) => (
              <Link
                key={category.category_id}
                to={`/products?category=${category.category_id}`}
                className="footer-tags-link"
              >
                <span >{category.name}</span>
              </Link>
            ))}
          </div>
        </div>
      </div>
      <div class="footer-bottom">
        <div class="payment-icons">
          <img
            src="https://demo1.wpthemego.com/themes/sw_bosmarket/wp-content/uploads/2017/12/fot2.png"
            alt="Maestro"
          />
        </div>
        <p>©2025 Designed by Dehleze.Com</p>
      </div>
    </footer>
  );
};

export default Footer;
